import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["count"];

  connect() {
    this.number = Math.floor(Math.random() * 81) + 20; // Random between 20-100
    this.updateText();
    this.scheduleNextIncrement();
  }

  scheduleNextIncrement() {
    if (this.number < 100) {
      setTimeout(() => {
        this.number++;
        this.updateText();
        this.scheduleNextIncrement(); // Recursively schedule the next increment
      }, this.getRandomTime());
    }
  }

  updateText() {
    this.countTarget.textContent = `👇 ${this.number} people just tapped the button 👇`;
  }

  getRandomTime() {
    return Math.floor(Math.random() * 3500) + 500; // Random between 0.5s to 4s
  }
}
